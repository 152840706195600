<template>
  <div v-if="webinar">
    <component :is="webinar" :data="data" />
  </div>
</template>
<script>
export default {
  components: {
    bookingflix () { return import('@/components/bookingInfoPage/webinar/bookingflix') }
  },
  props: {
    data: Object
  },
  mounted () {
    var data = JSON.parse(this.data.atitle)

    if (typeof data.webinar === 'undefined' || !data.webinar) {
      return false
    }
    this.webinar = data.webinar
  },
  computed: {

  },
  data () {
    return {
      webinar: false
    }
  },
  methods: {
  }
}
</script>
<style lang="sass" scoped>
</style>
